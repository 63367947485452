import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Dropdown from "../components/Dropdown"
import Seo from "../components/seo"
import { Form, Button } from '@themesberg/react-bootstrap';
import Grid from '../components/Grid'
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHome, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield, faAngleDown, faEye, faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import Parse from '../vendor/Parse'
import { useLocation } from '@reach/router';
import NumberTable from '../components/NumberTable'
import { Modal } from '@themesberg/react-bootstrap';
import useAuth from "../hooks/userAuth";
import DatePicker from "../components/DatePicker"
const { useState } = React;

const Profile1 = "images/2021-01-30.jpg";
const IndexPage = () => {

    const location = useLocation();
    const qs = new URLSearchParams(location.search)
    const { isLogin } = useAuth()
    const [items, setItems] = useState([])
    const [query, setQuery] = useState(qs.get('search') || "")
    const [checklist, setChecklist] = useState([])
    const [suggestion, setSuggestion] = useState([])
    const [displayInfo, setDisplayInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [payload, setPayload] = useState({})
    const [isSuggestionLoading, setSuggestionLoading] = useState(false)
    const [types, setTypes] = useState([])
    const [selectedType, setSelectedType] = useState(null)
    const [attributePayload, setAttributePayload] = useState({})
    const [typeName, setTypeName] = useState("")
    const [attributeName, setAttributeName] = useState("")
    const [numOfAttribute, setNumOfAttribute] = useState(5)
    const [attributeValue, setAttributeValue] = useState([
        { label: 'string', value: 'string' },
        { label: 'array', value: 'array' },

    ])

    const [attributeArray, setAttributeArray] = useState([])
    const [selectedAttribute, setSelectedAttribute] = useState(attributeValue[0])
    React.useEffect(() => {
        if (types.length) {
            setSelectedType(types[0])
        }
    }, [types])
    const handleCreateType = async (e) => {
        console.log(payload)
        Parse.Cloud.run('createType', payload)
    }

    const getAllTypes = async (e) => {
        const res = await Parse.Cloud.run('getItemsMeta', {

        })
        setItems(res)
    }

    const searchNumbers = async (query) => {
        setIsLoading(true)
        const res = await getItems()

        setIsLoading(false)
    }

    const getItems = async () => {
        return await Parse.Cloud.run('getItemsMeta')
    }

    const notWhatsppFlow = ['admin', 'user'].includes(isLogin?.role)
    const getMySuggestion = async () => {
        setSuggestionLoading(true)
        const res = await Parse.Cloud.run('getMySuggestedNumber')
        setChecklist(res.checklist)
        setSuggestion(res.suggestion)
        setSuggestionLoading(false)
    }

    React.useEffect(() => {

        getAllTypes()

    }, [])



    const handleItem = async (item) => {

        if (notWhatsppFlow) {
            const res = await Parse.Cloud.run('querySpecificNumber', {
                _id: item._id
            })

            setDisplayInfo(<>
                {res.num} is detected at  {res.sourceId} on {res.date}
                <br />
                <a style={{ color: '#25D366', textDecoration: 'none' }} href={`https://api.whatsapp.com/send?phone=6587413159&text=Find Mobile Number`}>whatsapp</a> us if you need help getting the number
            </>)
        } else {
            const url = `https://api.whatsapp.com/send?phone=6587413159&text=` + encodeURIComponent(`Enquiry on ${item.num} with id ${item._id}`)
            navigate(url)
        }

    }

    const handleAddAttribute = async () => {
        const res = await Parse.Cloud.run('addAttribute', {
            typeName: selectedType?.name,
            attributeObject: {
                [attributeName]: selectedAttribute?.value
            }
        })
    }

    const handleClose = () => {
        setDisplayInfo(null)
    }

    const cols = [
        { label: 'id', key: 'objectId' },
        { label: 'Type', key: 'type' },
        { label: 'Name', key: 'name' },
        {
            label: 'Action', onRender: (item) => {
                return <><Button onClick={() => handleItem(item)}>
                    <FontAwesomeIcon icon={faEye} />
                </Button>
                    <Button onClick={() => navigate(`/manage?id=${item.objectId}`)}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                    <Button onClick={() => handleItem(item)}>
                        <FontAwesomeIcon icon={faEnvelopeOpen} />
                    </Button>
                </>
            }
        }]

    return <Layout afterSearch={<></>} >
        <h3 className="text-center">List</h3>
        {
            <>
                {
                    items?.length
                }
                <NumberTable cols={cols} isLoading={isLoading} items={items} onItem={item => handleItem && handleItem(item)} />
                <Modal as={Modal.Dialog} centered show={displayInfo} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title className="h6">Information</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={handleClose} />
                    </Modal.Header>
                    <Modal.Body>
                        {displayInfo}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            I Got It
                        </Button>
                        <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        }
    </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
